*, :before, :after {
  box-sizing: border-box;
}

ul, ol {
  padding: 0;
  list-style: none;
}

body, h1, h2, h3, h4, h5, p, ul, ol, form, figure, blockquote, dl, pre, dd {
  margin: 0;
}

body {
  word-wrap: break-word;
  min-height: 100vh;
  margin: 0;
}

img, picture, video {
  width: 100%;
  height: auto;
  padding: 0;
  display: block;
}

input, button, textarea, select {
  appearance: none;
  font: inherit;
  background: none;
  border: none;
  padding: 0;
  font-weight: 400;
}

img:not([alt]) {
  filter: blur(10px);
}

a {
  text-decoration: none;
}

@media (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url("peridotpe-regular.woff2.a172a1ac.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url("peridotpe-italic.woff2.e80f4bd7.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url("peridotpe-semibold.woff2.fc611194.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  src: url("peridotpe-semibolditalic.woff2.8e3bbfd2.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("peridotpe-bold.woff2.887b0892.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Peridot;
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url("peridotpe-bolditalic.woff2.85e2c805.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Monolisa;
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url("MonoLisa-Regular.ttf.fe31c332.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Monolisa;
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url("MonoLisa-RegularItalic.ttf.258d5666.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Monolisa;
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("MonoLisa-Bold.ttf.5ecc3e29.woff2") format("woff2");
  unicode-range: U+??;
}

@font-face {
  font-family: Monolisa;
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url("MonoLisa-BoldItalic.ttf.0a3a1378.woff2") format("woff2");
  unicode-range: U+??;
}

:root {
  font-family: var(--font-mono);
  --pre-size: 13px;
  --foreground: var(--colors-gray11);
  --background: var(--colors-gray1);
  --comment: var(--colors-gray11);
}

code[class*="language-"], pre[class*="language-"] {
  color: var(--foreground);
  font-size: var(--pre-size);
  text-shadow: none;
  font-weight: 400;
  font-family: var(--font-mono);
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  display: block;
  position: relative;
  background-color: var(--background) !important;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: var(--background);
}

:not(pre) > code {
  white-space: normal;
  color: var(--foreground);
  background: var(--background);
  text-shadow: none;
  font-size: 1.06em;
  font-weight: 400;
}

.token {
  color: #4bf3c8;
}

.token.maybe-class-name {
  color: #00daef;
}

.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.language-css .token, .token.selector {
  color: #4bf3c8;
}

.token.comment, .token.prolog, .token.cdata {
  color: #7e7e7e;
}

.token.punctuation {
  color: #7b6995;
}

.token.builtin, .token.constant, .token.class-name {
  color: #ffe261;
}

.token.boolean, .token.number {
  color: #b381c5;
}

.token.important, .token.atrule, .token.property, .token.keyword {
  color: #40b4c4;
}

.token.doctype, .token.operator, .token.inserted, .token.tag, .token.class-name, .token.symbol {
  color: #00daef;
}

.token.attr-name, .token.function, .token.deleted, .token.selector {
  color: #4bf3c8;
}

.token.attr-value, .token.regex, .token.char, .token.string {
  color: #b4dce7;
}

.token.entity, .token.url, .token.variable, .token.script {
  color: var(--foreground);
}

:root {
  --colors-gray1: #121212;
  --colors-gray2: #1a1a1a;
  --colors-gray3: #212121;
  --colors-gray4: #292929;
  --colors-gray5: #2e2e2e;
  --colors-gray6: #363636;
  --colors-gray7: #424242;
  --colors-gray8: #4f4f4f;
  --colors-gray9: #707070;
  --colors-gray10: gray;
  --colors-gray11: #bababa;
  --colors-gray12: #ededed;
  --colors-bg: var(--colors-gray1);
  --colors-border: var(--colors-gray7);
  --colors-hiContrast: var(--colors-gray11);
  --colors-loContrast: var(--colors-gray10);
  --colors-accent: #4bf3c8;
  --space-xl: calc(7rem + 5vmax);
  --space-lg: calc(5rem + 4vmax);
  --space-md: calc(3rem + 3vmax);
  --space-sm: calc(.5rem + 1vmax);
  --space-xs: .35rem;
  --gutter: calc(8px + 1.2vw);
  --font: "Peridot", sans-serif;
  --font-mono: "Monolisa", monospace !important;
  --leva-colors-elevation2: var(--colors-bg) !important;
  --leva-colors-elevation3: var(--colors-gray4) !important;
  --leva-colors-accent1: var(--colors-accent) !important;
  --leva-colors-accent2: var(--colors-accent) !important;
  --leva-colors-accent3: var(--colors-accent) !important;
  --leva-colors-highlight3: var(--colors-gray1) !important;
  --leva-fonts-mono: "Monolisa", monospace !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
  color: var(--colors-loContrast);
  background-color: var(--colors-bg);
}

html, body, h1 {
  font-size: 16px;
  line-height: 1.6;
  font-family: var(--font);
  font-feature-settings: "ss01";
}

button.npm-install {
  font-family: var(--font-mono);
  font-size: 14px;
}

button.npm-install:focus {
  outline-offset: 3px;
  outline: 1px solid var(--colors-accent);
}

h2 {
  font-size: calc(20px + 2vmax);
  color: var(--colors-hiContrast);
  padding-top: var(--space-sm);
  border-top: 1px solid var(--colors-border);
  margin-left: -.075em;
  font-weight: 600;
  line-height: 1;
}

h1, h3 {
  color: var(--colors-hiContrast);
  font-size: 18px;
  font-weight: 600;
}

h4 {
  color: var(--colors-hiContrast);
  font-size: 16px;
  font-weight: 600;
}

#root > header {
  padding: 20px var(--gutter) 0 var(--gutter);
  margin: 0 auto;
  margin-bottom: var(--space-xl);
  justify-content: space-between;
  align-items: baseline;
  width: auto;
  padding-bottom: .2rem;
  font-weight: 600;
  display: flex;
}

#root > main {
  padding: 0 var(--gutter) 10vmax var(--gutter);
  flex: 1;
  width: auto;
  max-width: 60rem;
  margin: 0 auto;
}

#root > footer {
  padding: var(--space-sm) var(--gutter) var(--space-sm) var(--gutter);
  flex: 1;
  justify-content: flex-end;
  align-items: baseline;
  width: auto;
  margin: 0 auto;
  font-size: 15px;
  display: flex;
}

#root > header code {
  font-size: 15px;
}

#root > header .links {
  gap: var(--space-sm);
  place-items: center flex-end;
  display: flex;
}

a.version {
  background-color: var(--colors-bg);
  border: 1px solid var(--colors-hiContrast);
  color: var(--colors-hiContrast);
  outline-offset: 3px;
  z-index: 999;
  right: calc(var(--space-sm)  + 3px);
  padding: 4px 6px;
  position: fixed;
}

a.version:hover, a.version:focus, a.version[data-toast="true"] {
  border: 1px solid var(--colors-accent);
  color: var(--colors-accent);
  outline: none;
}

a.version[data-toast="true"] {
  animation: .5s ease-out toast;
}

#root > header a {
  font-family: var(--font-mono);
  font-size: 14px;
  font-weight: 400;
}

a {
  color: var(--colors-hiContrast);
  font-weight: 600;
  text-decoration: none;
  position: relative;
  overflow: visible;
}

a:before {
  content: " ";
  width: calc(100% + 1rem);
  display: block;
  position: absolute;
  top: 0;
  left: -.5rem;
}

a:focus-visible {
  outline: 1px solid var(--colors-accent);
  outline-offset: 3px;
}

a:focus, a:hover {
  color: var(--colors-accent);
}

.prose > * {
  margin-bottom: var(--space-md);
  max-width: 38rem;
}

.prose > p {
  margin-bottom: var(--space-sm);
}

.prose h1 {
  margin-top: 0;
  margin-bottom: var(--space-sm);
}

.prose h2 {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-lg);
  display: block;
}

.prose > .intro {
  margin-bottom: var(--space-xl);
}

.prop-heading {
  align-items: baseline;
  gap: var(--space-xs);
  margin-top: var(--space-md);
  margin-bottom: calc(var(--space-sm)  - 4px);
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--colors-border);
  display: flex;
}

.prop-heading code {
  color: var(--colors-loContrast);
  font-size: 14px;
}

.prose > [data-block] {
  margin: var(--space-sm) 0 var(--space-sm) 0;
}

.prose > table {
  border-collapse: collapse;
  width: 100%;
  max-width: 38rem;
}

table thead tr th {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--colors-border);
  font-size: 18px;
}

table tbody tr {
  transition: color 1s ease-out;
}

table tbody tr:hover {
  color: var(--colors-hiContrast);
  transition: color ease-out;
}

table tbody tr:first-child td {
  padding-top: var(--space-sm);
}

table tbody tr td:first-child, table tbody tr td:nth-child(2) {
  width: 8rem;
}

tr {
  cursor: default;
}

th {
  text-align: left;
  color: var(--colors-hiContrast);
  padding-bottom: var(--space-sm);
}

li {
  margin: var(--space-xs) 0;
}

.prose > p:first-of-type {
  margin-top: 0;
}

.prose > :last-child {
  margin-bottom: 0;
}

.example {
  grid-template-columns: 1fr;
  gap: 1rem;
  width: 100%;
  max-width: 60rem;
  display: grid;
}

.example .levas {
  order: 2;
}

@media screen and (width >= 42rem) {
  .example .levas {
    order: 2;
  }

  .example {
    grid-template-columns: 1fr 260px;
  }
}

.example .levas {
  margin-top: -5px;
  display: block;
}

.levas button:focus {
  outline: 1px solid var(--colors-accent);
}

.levas input::selection {
  color: #fff;
  background-color: #0000;
}

.example .sample {
  font-size: 14px;
  font-family: var(--font-mono);
  color: var(--colors-hiContrast);
}

[data-block="code"] {
  position: relative;
}

[data-block="code"] button {
  color: var(--colors-loContrast);
  background-color: #0000;
  position: absolute;
  bottom: 0;
  right: 0;
}

[data-block="code"] button:hover, [data-block="code"] button:focus {
  color: var(--colors-hiContrast);
}

[data-block="code"] button:active {
  transform: translateX(1px)translateY(1px);
}

@keyframes toast {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

/*# sourceMappingURL=index.2ea80a43.css.map */
